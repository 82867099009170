<template>
  <div>
    <b-row class="h-100" v-if="state === 'initialize'" align-h="center">
      <b-spinner label="Loading..."></b-spinner>
    </b-row>

    <b-row v-if="state != 'initialize'">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <h4>New Self Service Request</h4>
            </b-col>
          </b-row>
          <b-row v-if="this.amessage!=null">
          <b-col cols="12">
            <b-form-input disabled v-model="amessage" style="background:beige"></b-form-input>
          </b-col>
        </b-row>
          <b-row>
            <b-col cols="4">
              <label>Company <span class="text-red">*</span></label>
              <b-input-group>
                <b-form-input v-model="request.company.description" :disabled="true"></b-form-input>
                <b-input-group-append>
                  <b-button size="sm" text="Button" variant="secondary" @click="openSearchModal()">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <label>Experiencing User <span class="text-red">*</span></label>
              <b-input-group>
                <b-form-input v-model="userFullname" :disabled="true"></b-form-input>
                <b-input-group-append>
                  <b-button size="sm" text="Button" variant="secondary" @click="openClientSearchModal()"
                    :disabled="request.company.description === null ? true : false">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <label>Location <span class="text-red">*</span></label>
              <!-- <b-form-input v-model="request.siteName" :disabled="true"></b-form-input> -->

              <b-form-select v-model="request.locationId" :disabled="userFullname === null">
                <b-form-select-option v-for="(item, index) in locationsForUser" :key="index" :value="item.locationId">{{
                  item.locationName }}</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label class="bold">Reporting User</label>
              <label>{{ request.reportingUser.name }}</label>
            </b-col>
            <b-col cols="8">
              <label>Issue Description <span class="text-red">*</span></label>
              <b-form-input v-model="request.issueDescription"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div class="material-box">
                <label class="material-label">Attachments</label>
                <b-form-file v-model="request.imageFile" @change="filesUploaded" placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."></b-form-file>
              </div>
            </b-col>
            <b-col cols="8">
              <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns"
                :busy="tableData.isLoading" sort-icon-left>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="removeDocument(row.item)" variant="red" size="sm"> Remove </b-button>
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button variant="red" squared class="mr-2" @click="goBackToSearch()">Cancel</b-button>
              <b-button variant="primary" squared @click="create()" :disabled="state === 'loading'">
                <b-spinner small v-if="state === 'loading'"></b-spinner> Create</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="result-modal" hide-footer no-close-on-backdrop @close="closeModal()">
      <template #modal-header="{}">
        <h5>Done!</h5>
      </template>
      <b-row>
        <b-col cols="12" class="text-center">
          <p>Your Ticket number is as follows:</p>
          <h3 class="text-center">{{ solarWindsTicketNumber }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center">
          <b-button variant="primary" @click="hideModal" class="mr-2 ml-2" squared>Done</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>

    <b-modal id="search-company-modal" hide-footer size="xl" no-header @close="closeSearchModal()">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="search.name"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2" @click="clearFilter()">Clear</b-button>
          <b-button variant="primary" squared @click="doSearchCompanies()">Search</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table striped hover :items="tableCompanyData.dataSource" :fields="tableCompanyData.tableColumns"
          :busy="tableCompanyData.isLoading" @row-clicked="addCompany" :per-page="tableCompanyData.resultsPerPage"
          :current-page="tableCompanyData.currentPage" sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button @click="addCompany(row.item)" size="sm" class="btn-icon">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>
        </b-table>
      </b-row>
      <b-row align-h="center">
        <b-pagination v-model="tableCompanyData.currentPage" :total-rows="companyRows"
          :per-page="tableCompanyData.resultsPerPage"></b-pagination>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-right">
          <b-button variant="red" class="mr-2" squared @click="closeSearchModal()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="search-client-modal" hide-footer size="xl" no-header @close="closeClientSearchModal()">
      <div v-if="contactMessage.show === true">
        <b-alert :show="dismissCountDown" dismissible :variant="contactMessage.type" @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged">{{ contactMessage.message }}</b-alert>
      </div>

      <b-form v-if="showClientContactAdd">
        <b-row>
          <b-col cols="4">
            <label>Company</label>
            <b-form-input v-model="contactValues.company.description" :disabled="true"></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>Name <span class="text-red">*</span></label>
            <b-form-input v-model="$v.contactValues.name.$model" @blur="$v.contactValues.name.$touch()"></b-form-input>
            <div v-if="$v.contactValues.name.$error" class="text-red font-weight-400 text-left">
              <p v-if="!$v.contactValues.name.required">This is a required field</p>
            </div>
          </b-col>
          <b-col cols="4">
            <label>Surname <span class="text-red">*</span></label>
            <b-form-input v-model="$v.contactValues.surname.$model"
              @blur="$v.contactValues.surname.$touch()"></b-form-input>
            <div v-if="$v.contactValues.surname.$error" class="text-red font-weight-400 text-left">
              <p v-if="!$v.contactValues.surname.required">This is a required field</p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <label>Landline Number <span class="text-red">*</span></label>
            <b-form-input type="email" v-model="$v.contactValues.landlineNumber.$model"
              @blur="$v.contactValues.landlineNumber.$touch()"></b-form-input>
            <div v-if="$v.contactValues.landlineNumber.$error" class="text-red font-weight-400 text-left">
              <p v-if="
                !$v.contactValues.landlineNumber.minLength ||
                !$v.contactValues.landlineNumber.maxLength ||
                !$v.contactValues.landlineNumber.numeric
              ">
                Please enter a valid number
              </p>
              <p v-if="!$v.contactValues.landlineNumber.required">This is a required field</p>
            </div>
          </b-col>
          <b-col cols="4">
            <label>Mobile Number <span class="text-red">*</span></label>
            <b-form-input v-model="$v.contactValues.cellNumber.$model"
              @blur="$v.contactValues.cellNumber.$touch()"></b-form-input>
            <div v-if="$v.contactValues.cellNumber.$error" class="text-red font-weight-400 text-left">
              <p v-if="
                !$v.contactValues.cellNumber.minLength ||
                !$v.contactValues.cellNumber.maxLength ||
                !$v.contactValues.cellNumber.numeric
              ">
                Please enter a valid number
              </p>
              <p v-if="!$v.contactValues.cellNumber.required">This is a required field</p>
            </div>
          </b-col>
          <b-col cols="4">
            <label>Email <span class="text-red">*</span></label>
            <b-form-input v-model="$v.contactValues.email.$model" @blur="$v.contactValues.email.$touch()"></b-form-input>
            <div v-if="$v.contactValues.email.$error" class="text-red font-weight-400 text-left">
              <p v-if="!$v.contactValues.email.email">Please enter a valid email</p>
              <p v-if="!$v.contactValues.email.required">This is a required field</p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <label>Location <span class="text-red">*</span></label>
            <b-form-select v-model="contactValues.location">
              <b-form-select-option v-for="(item, index) in possibleLocations" :key="index" :value="item">{{
                item.locationName
              }}</b-form-select-option>
            </b-form-select>
          </b-col>
          <!-- <b-col cols="4" v-if="contactValues.location.locationId === 0">
                        <label>Location Name<span class="text-red">*</span></label>
                        <b-form-input v-model="contactValues.newLocation.locationName"></b-form-input>
                        <p v-if="locationFilled === false" >This is a required field</p>
                    </b-col>
                    <b-col cols="4" v-if="contactValues.location.locationId === 0">
                        <label>Address<span class="text-red">*</span></label>
                        <b-form-input v-model="contactValues.newLocation.address"></b-form-input>
                        <p v-if="locationFilled === false" >This is a required field</p>
                    </b-col> -->
        </b-row>
        <!-- <b-row>
                    <b-col cols="4" v-if="contactValues.location.locationId === 0">
                        <label>City</label>
                        <b-form-input v-model="contactValues.newLocation.city"></b-form-input>
                    </b-col>
                    <b-col cols="4" v-if="contactValues.location.locationId === 0">
                        <label>Country</label>
                        <b-form-input v-model="contactValues.newLocation.country"></b-form-input>
                    </b-col>
                    <b-col cols="4" v-if="contactValues.location.locationId === 0">
                        <label>Postal Code</label>
                        <b-form-input v-model="contactValues.newLocation.postalCode"></b-form-input>
                    </b-col>
                </b-row> -->
        <hr class="mx-3" />
        <b-row>
          <b-col class="text-right">
            <b-button variant="red" squared @click="showClientContactAdd = !showClientContactAdd"
              class="ml-2">Cancel</b-button>
            <b-button variant="primary" squared @click="startCreateClientContact" class="ml-2"
              :disabled="checkContactValues($v.contactValues.$invalid)">
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <b-row v-if="!showClientContactAdd">
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="clientSearch.name"></b-form-input>
        </b-col>
        <b-col cols="4">
          <label>Surname</label>
          <b-form-input v-model="clientSearch.surname"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" v-if="!showClientContactAdd" />
      <b-row v-if="!showClientContactAdd">
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2" @click="clearClientSearchFilter()">Clear</b-button>
          <b-button variant="primary" squared @click="doSearchClientList()">Search</b-button>
        </b-col>
      </b-row>

      <b-row v-if="!showClientContactAdd">
        <b-col>
          <b-table striped hover :items="tableClientCompanyData.dataSource" :fields="tableClientCompanyData.tableColumns"
            :busy="tableClientCompanyData.isLoading" @row-clicked="addClientToRequest"
            :per-page="tableClientCompanyData.resultsPerPage" :current-page="tableClientCompanyData.currentPage" sort-icon-left>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button @click="addClientToRequest(row.item)" size="sm" class="btn-icon">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </b-button>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row align-h="center" v-if="!showClientContactAdd">
        <b-pagination v-model="tableClientCompanyData.currentPage" :total-rows="rows"
          :per-page="tableClientCompanyData.resultsPerPage"></b-pagination>
      </b-row>
      <b-row class="mt-4">
        <b-col v-if="!showClientContactAdd">
          <b-button variant="primary" @click="addNewContactToggle()"> Add New Contact </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button v-if="!showClientContactAdd" variant="red" class="" squared
            @click="closeClientSearchModal()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import { mapMutations, mapActions } from 'vuex';

export default {
  data: () => ({
    amessage: null,
    state: 'initialize',
    counter: 1,
    locationFilled: null,
    userFullname: null,
    contactValues: {
      name: null,
      surname: null,
      email: null,
      cellNumber: null,
      company: { description: null },
      location: { locationId: null },
      landlineNumber: null,
      newLocation: { locationName: null, address: null, city: null, country: null, postalCode: null },
    },
    possibleLocations: [],
    showClientContactAdd: false,
    solarWindsTicketNumber: null,
    newClientContact: {},
    request: {
      experiencingUser: { name: null },
      siteName: null,
      issueDescription: null,
      locationId: null,
      reportingUser: {
        name: null,
      },
      company: { description: null },
    },
    locationsForUser: [],
    ogClientList: [],
    tableClientCompanyData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Contact Number',
          key: 'phone',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
    tableCompanyData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
    search: {
      name: null,
    },
    tableData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Document Name',
          key: 'documentName',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
    clientSearch: {
      name: null,
      surname: null,
    },
    contactMessage: {
      message: '',
      type: 'success',
      show: false,
    },

    dismissSecs: 10,
    dismissCountDown: 0,
  }),
  created() {
    this.request.reportingUser = {
      name: localStorage.getItem('user'),
    };
    this.setBreadcrumb([
      {
        text: 'Self Service Request',
        to: { path: '/selfService/search' },
      },
      {
        text: 'Add',
        active: true,
      },
    ]);
    this.state = 'show';
    this.tableData.isLoading = false;
  },
  methods: {
    ...mapActions([
      'addSelfServiceRequest',
      'searchCompanies',
      'searchClientByCompany',
      'createContact',
      'uploadDocument',
      'searchLocationByCompany',
      'registerNewLocation',
      
    ]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addErrorMessage', 'clearMessage', 'addWarningMessage']),

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    doSearchClientList() {
      this.tableClientCompanyData.isLoading = true;

      let searchName = this.clientSearch.name !== null ? this.clientSearch.name.toLowerCase() : null;
      let searchSurname = this.clientSearch.surname !== null ? this.clientSearch.surname.toLowerCase() : null;
      let newArray = this.ogClientList.filter(function (el) {
        return (
          (el.name !== null ? el.name.toLowerCase().includes(searchName) : false) ||
          (el.surname !== null ? el.surname.toLowerCase().includes(searchSurname) : false)
        );
      });

      this.tableClientCompanyData.dataSource = newArray;
      this.tableClientCompanyData.isLoading = false;
    },
    clearClientSearchFilter() {
      this.tableClientCompanyData.isLoading = true;

      this.clientSearch = {
        name: null,
        surname: null,
      };
      this.tableClientCompanyData.dataSource = this.ogClientList;

      this.tableClientCompanyData.isLoading = false;
    },
    checkContactValues(invalidCheck) {
      let locationCheckPassed = true;
      if (this.contactValues.location.locationId === null) {
        locationCheckPassed = false;
      }
      if (this.contactValues.location.locationId === 0) {
        if (
          this.contactValues.newLocation.locationName === null ||
          this.contactValues.newLocation.locationName === ''
        ) {
          locationCheckPassed = false;
        }
        if (this.contactValues.newLocation.address === null || this.contactValues.newLocation.address === '') {
          locationCheckPassed = false;
        }
      }
      if (invalidCheck === false && locationCheckPassed === true) {
        return false;
      } else {
        return true;
      }
    },
    addNewContactToggle() {
      this.showClientContactAdd = !this.showClientContactAdd;
      this.contactValues = {
        name: null,
        surname: null,
        email: null,
        cellNumber: null,
        company: this.request.company,
        location: { locationId: null },
        landlineNumber: null,
        newLocation: { locationName: null, address: null, city: null, country: null, postalCode: null },
      }

      //console.log('request', this.request);
      let request = {
        companyId: this.request.company.id,
      };
      this.$store.commit('setSearchLocationByCompanyRequest', request);
      this.searchLocationByCompany().then((res) => {
        this.possibleLocations = res.data;
        // this.possibleLocations.unshift({locationId: 0, locationName: 'Add new location'})
      });
    },
    filesUploaded(e) {
      const selectedDocument = e.target.files[0];
      if (selectedDocument.size > 20000000) {
        this.clearMessage();
        this.addErrorMessage({ error: '', message: 'Document to big to be uploaded' });
        return;
      }
      this.createBase64File(selectedDocument);
      this.uploadFile = null;
    },
    createBase64File(fileObject) {
      this.counter++;
      const reader = new FileReader();

      reader.onload = (e) => {
        this.file1 = e.target.result;

        let documentObject = {
          id: this.counter,
          documentName: fileObject.name,
          description: fileObject.name,
          base64Data: this.file1,
        };

        this.tableData.dataSource.push(documentObject);
        this.request.imageFile = null;
      };

      reader.readAsDataURL(fileObject);
    },
    removeDocument(rowItem) {
      this.tableData.isLoading = true;
      for (let i = 0; i < this.tableData.dataSource.length; i++) {
        const element = this.tableData.dataSource[i];
        if (element.id === rowItem.id) {
          this.tableData.dataSource.splice(i, 1);
        }
      }

      this.tableData.isLoading = false;
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'result-modal', '#btnShow');
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'result-modal', '#btnShow');
      this.$router.push({ path: '/selfService/search' });
    },
    openSearchModal() {
      this.$root.$emit('bv::show::modal', 'search-company-modal', '#btnShow');
      this.doSearchCompanies();
    },
    closeSearchModal() {
      this.$root.$emit('bv::hide::modal', 'search-company-modal', '#btnShow');
    },
    doSearchCompanies() {
      this.tableCompanyData.isLoading = true;
      let request = [];
      if (this.search.name !== null) {
        request.push({ key: 'companyName', value: this.search.name });
        //console.log('this search name' + this.search.name);
      }
      this.$store.commit('setSearchCompaniesRequest', request);
      this.searchCompanies()
        .then((res) => {
          this.tableCompanyData.dataSource = res.data;
          this.tableCompanyData.isLoading = false;
        })
        .catch(() => {
          this.tableCompanyData.isLoading = false;
        });
    },
    clearFilter() {
      this.search = {
        name: null,
      };
      this.doSearchCompanies();
    },
    addCompany(rowItem) {
      this.request.company = rowItem;
      this.request.experiencingUser = null;
      this.userFullname = null;
      this.closeSearchModal();
    },
    openClientSearchModal() {
      this.$root.$emit('bv::show::modal', 'search-client-modal', '#btnShow');
      this.contactValues = {
        name: null,
        surname: null,
        email: null,
        cellNumber: null,
        company: this.request.company,
        location: { locationId: null, locationName: null },
        newLocation: { locationName: null, address: null, city: null, country: null, postalCode: null },
        landlineNumber: null,
      };
      this.doSearchByCompany();
    },
    closeClientSearchModal() {
      this.$root.$emit('bv::hide::modal', 'search-client-modal', '#btnShow');
    },
    doSearchByCompany() {
      this.tableClientCompanyData.isLoading = true;
      let request = {
        companyID: this.request.company.id,
      };
      this.$store.commit('setSearchClientByCompanyRequest', request);

      this.searchClientByCompany()
        .then((res) => {
          this.ogClientList = res.data;
          this.tableClientCompanyData.dataSource = res.data;
          this.tableClientCompanyData.isLoading = false;
        })
        .catch(() => {
          this.tableClientCompanyData.isLoading = false;
        });
    },
    addClientToRequest(rowItem) {
      this.userFullname = rowItem.name + ' ' + rowItem.surname;
      this.request.experiencingUser = rowItem;

      this.closeClientSearchModal();

      let request = {
        companyId: this.request.company.id,
      };

      this.$store.commit('setSearchLocationByCompanyRequest', request);
      this.searchLocationByCompany().then((res) => {
        this.locationsForUser = res.data;
        //console.log('locations', this.locationsForUser);
      });
    },
    startCreateClientContact() {
      // if (this.contactValues.location.locationId === 0) {
      //     this.$store.commit('setLocationCreateRequest', this.contactValues.newLocation)
      //     this.registerNewLocation()
      //     .then((res) => {
      //         this.contactValues.location = {
      //             locationId: res.data.result.locationId,
      //             locationName: this.contactValues.newLocation.locationName
      //         }
      //         this.doCreateClientContact()
      //     })
      //     .catch(() => {
      //     })
      // }
      // else{
      this.doCreateClientContact();
      // }
    },
    doCreateClientContact() {
      this.state = 'loading';

      let request = {
        name: this.contactValues.name,
        surname: this.contactValues.surname,
        email: this.contactValues.email,
        cellNumber: this.contactValues.cellNumber,
        companyID: this.contactValues.company.id,
        locationID: this.contactValues.location.locationId,
        location: this.contactValues.newLocation.locationName,
        phone: this.contactValues.landlineNumber,
      };
      this.$store.commit('setContactCreateRequest', request);
      this.createContact()
        .then((res) => {
          if (res.data.result.companyID !== this.contactValues.company.id) {
            this.contactMessage.message = 'Contact is already created for the company ' + res.data.result.company;
            this.contactMessage.type = 'warning';
            this.contactMessage.show = true;
            this.dismissCountDown = this.dismissSecs;
          } else {
            this.contactMessage.message = 'Contact created!';
            this.contactMessage.type = 'success';
            this.contactMessage.show = true;
            this.dismissCountDown = this.dismissSecs;
          }
          this.showClientContactAdd = !this.showClientContactAdd;
          this.doSearchByCompany();
          this.state = 'show';
        })
        .catch(() => { });
    },

    create() {
      this.amessage = null;

      if (this.request.company == null || this.request.company.id == null ) {
        this.amessage = 'Company Required'
        return;
      }
      if (this.request.experiencingUser == null ) {
        this.amessage = 'Experience User Required'
        return;
      }
      
      if (this.request.locationId == null || this.request.locationId == 0) {
        this.amessage = 'Location Required'
        return;
      }

      if (this.request.issueDescription == null || this.request.issueDescription == '') {
        this.amessage = 'Issue Description Required'
        return;
      }
      
      // if (this.request.experiencingUser.clientContactId == null || this.request.experiencingUser.clientContactId == 0) {
      //   this.amessage = 'Client Required'
      //   return;
      // }

      let request = {
        room: null,
        clientId: this.request.experiencingUser.clientContactId,
        client: this.request.experiencingUser.name + ' ' + this.request.experiencingUser.surname,
        locationId: this.request.locationId,//this.request.siteName,
        subject: this.request.issueDescription,
        details: this.request.issueDescription,
        documents: this.tableData.dataSource,
      };
      console.log("request", request)

      this.state = 'loading';
      ////console.log(request);

      this.$store.commit('setSsrAddRequest', request);
      this.addSelfServiceRequest()
        .then((res) => {
          this.solarWindsTicketNumber = res.data.solarWindsTicketNumber;
          this.showModal();
          this.state = 'show';
        })
        .catch(() => {
          this.state = 'show';
          this.tableData.isLoading = false;
        });
    },
    goBackToSearch() {
      this.$router.push({ path: '/selfService/search' });
    },
  },
  computed: {
    rows() {
      return this.tableClientCompanyData.dataSource.length;
    },
    companyRows() {
      return this.tableCompanyData.dataSource.length;
    },
  },

  validations: {
    contactValues: {
      name: { required },
      surname: { required },
      location: { required },
      landlineNumber: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      cellNumber: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      email: { required, email },
    },
  },
};
</script>
